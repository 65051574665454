import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import Component from './ContentWrapper'
import { reactComponentNodeType } from '../../utils/constants'

export default Node.create({
    name: reactComponentNodeType,

    group: 'block',

    content: 'inline*',

    parseHTML() {
        return [
            {
                tag: reactComponentNodeType,
            },
        ]
    },
    renderHTML({ HTMLAttributes }) {
        return [reactComponentNodeType, mergeAttributes(HTMLAttributes), 0]
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component)
    },
})

import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React, { useRef, useEffect, useState } from 'react';
import { Button } from '../../../Button/Button';
import { imageNodeType, reactComponentNodeType, blockquoteNodeType } from '../../utils/constants';
import { useShowToast } from '@hooks/useShowToast';
import s from './contentWrapper.module.scss';
import cn from 'classnames';
import { Node } from 'prosemirror-model'; // Импортируем Node из prosemirror-model

export default function Component(props: NodeViewProps) {
  const {
    editor,
    node
  } = props as NodeViewProps & {
    node: Node;
  }; // Уточняем тип node как Node из ProseMirror
  const [isInBlockquote, setIsInBlockquote] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    showToast
  } = useShowToast();
  useEffect(() => {
    // TODO: убрать в отдельную функцию, такая же в корне
    const {
      $from
    } = editor.state.selection;
    for (let depth = 1; depth <= $from.depth; depth++) {
      let parent = $from.node(depth);
      if (parent.type.name === blockquoteNodeType) {
        setIsInBlockquote(true);
      }
    }
  }, [node]);
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result as string;
      editor.chain().focus().insertContent([{
        type: imageNodeType,
        attrs: {
          src: dataUrl,
          alt: 'A user picture',
          title: file.name
        }
      }, {
        type: reactComponentNodeType
      }]).run();
    };
    reader.onerror = () => {
      showToast({
        variant: 'error',
        text: 'error.default'
      });
    };
    reader.readAsDataURL(file);
  };
  return <NodeViewWrapper className={s.reactComponent} data-sentry-element="NodeViewWrapper" data-sentry-component="Component" data-sentry-source-file="ContentWrapper.tsx">
            {node.content.size === 0 && editor.isEditable && !isInBlockquote && <Button id='add_media_to_post_button' onClick={() => fileInputRef.current && fileInputRef.current.click()} size="small" color="Zhen Zhu Bai Pearl" icons={{
      left: {
        name: 'plus'
      }
    }} />}
            <input type="file" accept="image/*" style={{
      display: 'none'
    }} ref={fileInputRef} onChange={handleFileUpload} />

            <NodeViewContent as="p" className={cn(s.content, 'is_editable')} data-sentry-element="NodeViewContent" data-sentry-source-file="ContentWrapper.tsx" />
        </NodeViewWrapper>;
}
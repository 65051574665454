import { Editor, EditorContent, JSONContent, useEditor } from '@tiptap/react';
import React, { useEffect, useState } from 'react';
import { generateHTML } from '@tiptap/html';
import parse from 'html-react-parser';
import { BubbleMenuComponent } from './components/BubbleMenu/BubbleMenu';
import { componentExtensionsConfig } from './utils/config';
import { validateText } from './utils/validate';
import { JsonValue } from '@prisma/client/runtime/library';
import Placeholder from '@tiptap/extension-placeholder';
import { headingNodeType, reactComponentNodeType } from './utils/constants';
import { useTranslation } from '@hooks/useTranslation';
const DEFAULT_CONTENT = `<h1></h1><reactcomponent><p></p></reactcomponent>`;
interface TextEditorProps {
  content: JsonValue[] | null | undefined;
  isEditable?: boolean;
  client?: boolean;
}

/**
 * Тектовый редактор
 *
 * Возвращается сам редактор и вью.
 * Смысла разделять не нашел, тк вью без хука существовать не может
 */

export const TextEditor = ({
  content,
  isEditable = false,
  client = false
}: TextEditorProps) => {
  // Призма вернет JsonValue[]
  const _content = content as JSONContent[];
  const {
    translate: tr
  } = useTranslation();
  const [editorInstance, setEditorInstance] = useState<Editor | null>(null);

  /**
   * Узел управления редактором
   * Документацию можно найти на сайте tiptap
   */

  const editor = useEditor({
    extensions: [...componentExtensionsConfig,
    /**
     * Вынужденая мера положить конфиг тут из-за переводов
     */

    Placeholder.configure({
      showOnlyCurrent: false,
      placeholder: ({
        node
      }) => {
        if (node.type.name === headingNodeType) {
          return tr({
            id: 'placeholder.title'
          });
        }
        return tr({
          id: 'placeholder.write.your.thoughts'
        });
      }
    })],
    content: _content && _content.length ? {
      type: 'doc',
      content: _content
    } : DEFAULT_CONTENT,
    autofocus: false,
    editable: isEditable,
    onCreate: ({
      editor
    }) => {
      setEditorInstance(editor);
    },
    immediatelyRender: client,
    onUpdate: ({
      editor
    }) => {
      const {
        state,
        commands
      } = editor;
      const {
        selection,
        doc
      } = state;
      const {
        $from
      } = selection;
      const lastNode = doc.content.lastChild;
      const currentNode = $from.node();
      const isCurrentNodeLast = currentNode === lastNode;
      const isCurrentNodeReactComponent = currentNode.type.name === reactComponentNodeType || currentNode.type.name === headingNodeType;
      const isCurrentNodeEmpty = currentNode.content.size === 0;
      if (isCurrentNodeLast && isCurrentNodeReactComponent && !isCurrentNodeEmpty) {
        const currentPos = editor.state.selection.$anchor.pos;
        commands.insertContentAt(doc.content.size, {
          type: reactComponentNodeType
        });
        editor.commands.setTextSelection(currentPos);
      }
      validateText(editor.getJSON());
    }
  });

  // На клиенте, если контент сначала undefined а потом приходит
  // компонент не обновляется. Заметно в админке
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (editor && _content?.length && !loaded && client) {
      editor.commands.setContent(_content);
      setLoaded(true);
    }
  }, [_content, editor, loaded]);
  if (!editor) {
    /**
     * В контенте в картинках не должно быть src, тк это абсолютные пути и будут вести
     * на /posts/create/41ac6e464b4d4728cb6a9ac8f5e9210047913e469a2f14d39021f6136f405422.png
     *
     *TODO: UPDATE поведение не пропало
     */

    const sanitizedContent = _content.map(node => {
      if (node.type === 'image') {
        return {
          ...node,
          attrs: {
            ...node.attrs,
            src: undefined
          }
        };
      }
      return node;
    });
    const html = generateHTML({
      type: 'doc',
      content: sanitizedContent
    }, componentExtensionsConfig);
    return {
      view: <div className="tip-tap-wrapper">
                    {parse(html)}
                </div>,
      editor: null
    };
  }
  const view = <div className="tip-tap-wrapper">
            {editorInstance && <BubbleMenuComponent editor={editorInstance} />}
            <EditorContent editor={editorInstance} />
        </div>;
  return {
    view,
    editor: editorInstance
  };
};
import { LinkNode } from '../components/Link/LinkNode'
import ReactComponent from '../components/ContentWrapper/ContentWrapperNode'
import { ImageNode } from '../components/Image/ImageNode'
import { DocumentNode } from '../components/Document/DocumentNode'
import StarterKit from '@tiptap/starter-kit'

/**
 * Библиотека tiptap построена на безе ProseMirror и для внедрения компонентов/расширений использует расширения ProseMirror
 * Можно создавать свои узлы, можно расширять существующие. Все они находятся в папке component на уровень выше
 */

export const componentExtensionsConfig = [
    StarterKit.configure({
        document: false, // Поскольку используем кастомный документ, нам не нужен документ из кита
        bulletList: false,
        orderedList: false,
        listItem: false,
    }),
    ImageNode,
    LinkNode,
    DocumentNode,
    ReactComponent,
]

import React, { FC } from 'react';
import { ITooltip, Tooltip } from 'react-tooltip';
import s from './tooltip.module.scss';
import cn from 'classnames';
interface TooltipProps extends ITooltip {}
export const CustomTooltip: FC<TooltipProps> = ({
  children,
  className,
  ...props
}) => <Tooltip className={cn(s.tooltip, className)} place={'right'} classNameArrow={s.tooltip__arrow} {...props} data-sentry-element="Tooltip" data-sentry-component="CustomTooltip" data-sentry-source-file="CustomTooltip.tsx">
        {children}
    </Tooltip>;
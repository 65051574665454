import React from 'react';
import cn from 'classnames';
import { SpriteIcon, SpriteIconsKeys } from '@components/Sprites/SpriteIcon';
import s from './bubbleMenu.module.scss';
interface EditorButtonProps {
  command: () => void;
  isActive: boolean;
  iconName: Extract<SpriteIconsKeys, 'bold' | 'italic' | 'blockquote' | 'link'>;
  width: number;
  height: number;
}
export const EditorButton: React.FC<EditorButtonProps> = ({
  command,
  isActive,
  iconName,
  width,
  height
}) => {
  return <button onClick={e => {
    e.preventDefault();
    command();
  }} className={cn(s.bubbleMenu__item, isActive ? s.bubbleMenu__item_active : '')} data-sentry-component="EditorButton" data-sentry-source-file="EditorButton.tsx">
            <SpriteIcon name={iconName} width={width} height={height} data-sentry-element="SpriteIcon" data-sentry-source-file="EditorButton.tsx" />
        </button>;
};
import { Folders } from '@services/s3/constants';
import cn from 'classnames';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import { FC, memo, useMemo } from 'react';
import s from './imgS3.module.scss';
type ImgS3Props = ImageProps & {
  boxClassname?: string;
  src: string;
  folder: Folders;
  priority?: boolean;
};
const CLOUDFLARE_OPTIMIZE_PARAMS = ['format=webp', 'onerror=redirect', 'metadata=copyright', 'compress=true', 'lossless=true'];
const cloudflareLoader = ({
  src,
  width,
  quality = 55
}: ImageLoaderProps) => {
  const params = [`width=${width}`, `quality=${quality}`, ...CLOUDFLARE_OPTIMIZE_PARAMS, 'cacheTTL=31536000'];
  return `${process.env.NEXT_PUBLIC_R2_GET_DOMAIN}/cdn-cgi/image/${params.join(',')}/${src.startsWith('/') ? src.slice(1) : src}`;
};
const getPath = (name: string, folder: Folders) => {
  if (!name) return '';
  return `${folder}/${encodeURIComponent(name)}`;
};

/**
 * priority выставляем если картинка сразу появляется во вьюпорте при загрузке
 * для управления размером есть два варианта
 * 1) fill и boxClassname, в случае если размер не известен
 * 2) width, height, если размер известен
 * 
 * во всех случаях для экономии трафика рекоммендуется выставлять sizes, чтобы не подгружать слишком большие картинки
 * на больших вьюпортах. Общая рекоммендация поставить хотя бы  (max-width: 640px) 100vw, 1533px" где 1533px это размер контейнера 
 * выше которого никогда не будут грузится картинки
 * 
 * Замечание:
 * размер картинки будет формироваться в зависимости от вьюпорта с юнитами 'w'
 * например для viewport 500, он будет искать ближайшик вьюпорт из deviseSizes в next.config, допустим это 640 и указывать
 * w=860 при отправке. Однако если у вас высокая плотность пикселя window.devicePixelRatio=2, тогда он отправить 860 * 2 = 1720 (а точнее ближайший к этому)
 * 
 * так будет работать для fill, а для width, height он в srcet покажет при какой плотности пикселя какую ширину он отправит
 * 
 * делается это для того, чтобы на девайсах с высокой плотностью не страдало качество
 */

const ImgS3Component: FC<ImgS3Props> = ({
  boxClassname,
  src,
  folder,
  priority = false,
  sizes = '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw',
  loading = 'eager',
  quality = 75,
  width,
  height,
  alt = '',
  title,
  fill,
  ...props
}) => {
  const imageUrl = useMemo(() => {
    return getPath(src, folder);
  }, [src, folder]);
  if (!src) return null;
  return <div className={cn(s.box, boxClassname)} data-sentry-component="ImgS3Component" data-sentry-source-file="ImgS3.tsx">
            <Image {...props} {...fill ? {
      fill: true
    } : {
      width: width || 100,
      height: height || 100
    }} alt={alt} title={title} loader={cloudflareLoader} quality={quality} loading={priority ? 'eager' : loading || 'lazy'} src={imageUrl} priority={priority} sizes={fill ? '100vw' : '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'} placeholder="blur" blurDataURL={`data:image/svg+xml;base64,${btoa(`<svg width="${width || 100}" height="${height || 100}" 
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <rect width="100%" height="100%" fill="#f5f5f5"/>
                    </svg>`)}`} onError={e => {
      console.error('Image load error:', e);
    }} data-sentry-element="Image" data-sentry-source-file="ImgS3.tsx" />
        </div>;
};
export const ImgS3 = memo(ImgS3Component);
import React, { useState, useRef } from 'react';
import Image from 'next/image';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { ImgS3 } from '@components/UIKit/ImgS3/ImgS3';
import s from './image.module.scss';
import cn from 'classnames';
import { CustomTooltip } from '@components/UIKit/CustomTooltip/CustomTooltip';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { Button } from '@components/UIKit/Button/Button';
import { useTranslation } from '@hooks/useTranslation';
import { LOCAL_LOADED_IMAGE } from '@services/posts/constants';
import { useOnClickOutside } from '@hooks/useClickOutside';
const tooltipId = 'image';
export const TitTapImage = ({
  node,
  deleteNode,
  editor
}: NodeViewProps) => {
  const {
    src,
    alt,
    title
  } = node.attrs;
  const isEditable = editor.isEditable;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    translate: tr
  } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const showHovers = isEditable && (hovered || clicked);
  useOnClickOutside(wrapperRef, () => setClicked(false));
  if (!src) {
    return null;
  }
  return <NodeViewWrapper className="image-node" contentEditable={false} data-sentry-element="NodeViewWrapper" data-sentry-component="TitTapImage" data-sentry-source-file="Image.tsx">
            <CustomTooltip isOpen={showHovers} place='top' id={tooltipId} data-sentry-element="CustomTooltip" data-sentry-source-file="Image.tsx">
                <SpriteIcon width={17} name='info' data-sentry-element="SpriteIcon" data-sentry-source-file="Image.tsx" />
                {tr({
        id: 'drag.to.move'
      })}
            </CustomTooltip>
            <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} data-tooltip-id={tooltipId} ref={wrapperRef} className={cn(s.nodeImage, {
      [s.nodeImage__edit]: isEditable,
      [s.nodeImage__clicked]: clicked && isEditable
    })} onClick={() => setClicked(!clicked)}>
                {isEditable && <Button id='edit_image_button' onClick={deleteNode} className={s.nodeImage__delete} icons={{
        left: {
          name: 'trash',
          width: 17
        }
      }} size='small' color='Black' />}

                {src?.startsWith(LOCAL_LOADED_IMAGE) ? <Image src={src} alt={alt} title={title} fill objectFit="cover" data-drag-handle /> : <ImgS3 boxClassname={'image-node'} src={src} sizes="(max-width: 640px) 100vw, 1533px" fill priority objectFit="cover" data-drag-handle alt={`${title} | Post`} title={title} folder="posts" />}
            </div>
        </NodeViewWrapper>;
};
import { MAX_POST_CHARS_LIMIT, MAX_POST_HEADING_CHARS_LIMIT } from '@services/posts/constants'
import { editPostEvent } from '@services/posts/helpers'
import { JSONContent } from '@tiptap/react'

export const validateText = (content: JSONContent) => {
    let textContent = ''
    let headingText = ''

    const extractText = (node: JSONContent) => {
        if (node.type === 'heading') {
            headingText += node.content?.reduce((acc, el) => {
                if (el.type === 'text') {
                    return acc + (el.text || '')
                }
                return acc
            }, '')
        }
        if (node.text) {
            textContent += node.text
        }
        if (node.content) {
            node.content.forEach((childNode) => extractText(childNode))
        }
    }

    extractText(content)

    const trimmedText = textContent.trim()
    const validTextAndHeadingLength = trimmedText.length <= MAX_POST_CHARS_LIMIT && headingText.length <= MAX_POST_HEADING_CHARS_LIMIT

    const postState = trimmedText.length === 0 ? 'disabled' : !validTextAndHeadingLength ? 'error' : 'default'

    editPostEvent(postState)
}

import Document from '@tiptap/extension-document'
import { blockquoteNodeType, headingNodeType, reactComponentNodeType } from '../../utils/constants'

export const DocumentNode = Document.extend({
    content: `${headingNodeType}  block*`,
    addKeyboardShortcuts() {
        return {
            Enter: () => {
                const { state, commands } = this.editor
                const { selection } = state
                const { $from } = selection

                const isInHeading = $from.parent.type.name === headingNodeType

                if (isInHeading) {
                    const afterHeadingPos = $from.after()
                    const nextNode = state.doc.nodeAt(afterHeadingPos)

                    if (nextNode && nextNode.type.name === reactComponentNodeType) {
                        return commands.setTextSelection(afterHeadingPos + 1)
                    } else {
                        return this.editor.chain().insertContentAt(state.selection.head, { type: reactComponentNodeType }).focus().run()
                    }
                }

                // Логика для случая, когда мы внутри blockquote

                // TODO: убрать в отдельную функцию, такая же в корне
                for (let depth = 1; depth <= $from.depth; depth++) {
                    let parent = $from.node(depth)
                    if (parent.type.name === blockquoteNodeType) {
                        const afterParentPos = $from.after(depth)
                        const nextNode = state.doc.nodeAt(afterParentPos)

                        if (nextNode && nextNode.type.name === reactComponentNodeType) {
                            return commands.setTextSelection(afterParentPos + 1)
                        } else {
                            return this.editor
                                .chain()
                                .insertContentAt(afterParentPos, { type: reactComponentNodeType })
                                .setTextSelection(afterParentPos + 1)
                                .focus()
                                .run()
                        }
                    }
                }

                const isInReactComponent = $from.parent.type.name === reactComponentNodeType

                if (isInReactComponent) {
                    const afterHeadingPos = $from.after()

                    const nextNode = state.doc.nodeAt(afterHeadingPos)

                    if (nextNode && nextNode.type.name === reactComponentNodeType) {
                        return commands.setTextSelection(afterHeadingPos + 1)
                    } else {
                        const isCurrentNodeEmpty = $from.parent.content.size === 0

                        if (isCurrentNodeEmpty) {
                            return true
                        }

                        return true
                    }
                }

                // TODO: Клик на картинку не воспринимается как нода, нода берется корневая
                // Из-за этого невозможно отследить следующую ноду, тк это корень
                // Если с этим не разобраться, то при клике на картинку и нажатию Enter бесконечно можно вставлять компоненты

                // const isLastEmptyNode = $from.parent.type.name === 'doc'
                // if (isLastEmptyNode) {
                //     console.log('isLastEmptyNode')
                // }

                return this.editor.chain().insertContentAt(state.selection.head, { type: reactComponentNodeType }).focus().run()
            },
        }
    },
})

import { BubbleMenu, Editor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { EditorButton } from './EditorButton';
import s from './bubbleMenu.module.scss';
import { blockquoteNodeType, boldNodeType, headingNodeName, imageNodeType, italicNodeType, linkNodeType } from '../../utils/constants';
interface BubbleMenuComponentProps {
  editor: Editor;
}
export const BubbleMenuComponent = ({
  editor
}: BubbleMenuComponentProps) => {
  const hasLink = editor.isActive(linkNodeType);
  const onText = !editor.isActive(headingNodeName, {
    level: 1
  }) && !editor.isActive(imageNodeType);
  const [linkUrl, setLinkUrl] = useState('');
  const [isLinkInputVisible, setLinkInputVisible] = useState(false);
  const isLinkValid = linkUrl.trim().length > 0;
  const cancelLink = async () => {
    setLinkUrl('');
    setLinkInputVisible(false);
  };
  const setLink = async () => {
    if (hasLink) {
      await cancelLink();
      editor.chain().focus().unsetLink().run();
    } else {
      setLinkInputVisible(true);
    }
  };
  const submitLink = () => {
    if (isLinkValid) {
      editor.chain().focus().extendMarkRange(linkNodeType).setLink({
        href: linkUrl
      }).run();
      cancelLink();
    }
  };
  useEffect(() => {
    /**
     * Если поставить ссылку и нажать пробел, ссылка продолжает существовать
     * Это исправление меняет это поведение. Ставим ссылку, нажимаем пробел, на новом слое ссылки уже не будет
     */

    const handleSpacePress = (event: KeyboardEvent) => {
      if (event.key === ' ' && editor.isActive(linkNodeType)) {
        const {
          to
        } = editor.state.selection;
        editor.commands.setTextSelection({
          from: to,
          to
        });
        editor.chain().focus().unsetMark(linkNodeType).run();
      }
    };
    document.addEventListener('keydown', handleSpacePress);
    return () => {
      document.removeEventListener('keydown', handleSpacePress);
    };
  }, [editor]);
  return <BubbleMenu editor={editor} tippyOptions={{
    duration: 100,
    animation: 'fade',
    arrow: true,
    onHidden: () => setLinkInputVisible(false)
  }} data-sentry-element="BubbleMenu" data-sentry-component="BubbleMenuComponent" data-sentry-source-file="BubbleMenu.tsx">
            {onText && <div className={s.bubbleMenu}>
                    {isLinkInputVisible && <div className={s.bubbleMenu__linkInput}>
                            <input placeholder="Paste or type the link..." value={linkUrl} onChange={e => setLinkUrl(e.target.value)} onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            submitLink();
          }
        }} />
                            <button onClick={cancelLink}>
                                ✗
                            </button>
                        </div>}

                    {!isLinkInputVisible && <>
                            <EditorButton command={() => editor.chain().focus().toggleBold().run()} isActive={editor.isActive(boldNodeType)} iconName="bold" width={8} height={11} />
                            <EditorButton command={() => editor.chain().focus().toggleItalic().run()} isActive={editor.isActive(italicNodeType)} iconName="italic" width={8} height={11} />
                            <EditorButton command={() => editor.chain().focus().toggleBlockquote().run()} isActive={editor.isActive(blockquoteNodeType)} iconName="blockquote" width={12} height={10} />
                            <EditorButton command={setLink} isActive={editor.isActive(linkNodeType)} iconName="link" width={12} height={12} />
                        </>}
                </div>}
        </BubbleMenu>;
};
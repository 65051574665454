import Image from '@tiptap/extension-image'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { TitTapImage } from './Image'

export const ImageNode = Image.extend({
    draggable: true,
    addNodeView() {
        return ReactNodeViewRenderer(TitTapImage)
    },
})
